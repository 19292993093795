#main-container {

  .main-section {
    margin-left: 2vw;
    margin-top: 2vh;
  }
}

.euiBadge {
  font-size: 15px;
  line-height: 21px;
}

h3 {
  font-size: 24px !important;
}

.nested-question {
  margin-left: 50px;
  margin-top: 25px;
}

.form {
  max-width: 650px;
}

.form-large {
  max-width: 800px;
}
