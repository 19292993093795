@import "scss/main";
@import "components/index";

@import '~@elastic/eui/dist/eui_theme_light.css';
@import '~d3-v6-tip/src/d3-tip.css';

$primary: #E97761;
$accent: #A4ABFF;
$accentDark: #8F95F6;
$accentSubdued: rgba(164, 171, 255, 0.2);
$lightShade: #D3DAE6;
$mediumShade: #69707D;

body  {
    font-family: 'Inter', sans-serif !important;
}

.euiButton--accent {
    color: black;
    background-color: $accent;
}

.euiButton--accent:hover,
.euiButton--accent:not([class*='isDisabled']):hover,
.euiButton--accent:not([class*='isDisabled']):focus {
    color: $accent;
    background-color: $accentSubdued;
}

.euiButton--text {
    color: #494a50;
    background-color: $accentSubdued;
}

.container-fluid {
    padding: 0 !important;
    margin: 0 !important;
}

.euiButton--primary.euiButton--fill:not([class*='isDisabled']),
.euiButton--text.euiButton--fill:not([class*='isDisabled']) {
    background-color: $accent !important;
    border-color: $accent !important;
    color: white;
}

.euiButton-isDisabled {
    cursor: not-allowed !important;
}

.euiButtonEmpty--primary {
    color: $accent !important;
    border-bottom: 1px solid $accent;
}

.euiHeader {
    background: #fafbfd;
    border-bottom: none;
}

.euiNotificationBadge {
    background: $accentSubdued;
    color: $accentDark;
}

.euiStepNumber {
    background-color: $primary;
    color: white;
    outline-color: white;
}


.euiStepNumber--complete {
    background-color: $accentDark;
    color: black;
}

.euiStepNumber.euiStepNumber--incomplete {
    background-color: $lightShade !important;
    color: $mediumShade;
}

.euiStepHorizontal {
    cursor: default;
}

.d3-tip {
    max-width: 250px;
    font-size: 13px;
}

input[type='radio']:checked:before {
    background: grey;
    width: 20px;
    height: 20px;
    border-radius: 15px;
    position: absolute;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #174385;
}

input[type='radio']:after {
    width: 20px;
    height: 20px;
    border-radius: 15px;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #174385;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    background-color: #174385;
    padding: 5px;
    content: '';
    margin: 2px;
    display: block;
    border: 3px solid white;
}

input[type='radio']:disabled:before {
    background: #80808042;
    width: 20px;
    height: 20px;
    border-radius: 15px;
    position: absolute;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    z-index: 4;
    border: 1px solid #80808042;
}

input[type='radio']:disabled {
    width: 15px;
    height: 15px;
    background-color: #80808042;
    content: '';
    z-index: 4;
    border: 3px solid #80808042;
}

.euiHeader--dark {
    background-color: #07c;
    border-bottom-color: #07c;
}

.euiEmptyPrompt--vertical .euiEmptyPrompt__contentInner {
    max-width: 75em;
    margin: auto; }

p, h1, h2, h3, h4 {
    margin-bottom: 0 !important;
}

button {
    background: none;
    border: none;
    padding: 0px;
    margin: 0px;
    color: inherit;
    border-radius: 0px;
    font-size: inherit;
}

ol, ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    border: none;
}

.about-page {
    max-width: 1260px;
    margin: 0 auto !important;

    ol, ul {
        list-style: initial;
        padding-left: 32px;
        margin-bottom: 16px;
    }
}
/**
 *  Hero section
 */
.hero-section {
    padding-right: 0;
    padding-left: 5vw;
    padding-bottom: 5vh;
    background-color: $accent;
    height: 40vh;

    .hero-title {
        display: flex;
        align-items: center;
    }

    h1 {
        color: #ffffff;
    }

    p {
        color: #4D518B;
        text-align: center;
    }
}

.text-primary-dark {
    color: #333333;
}

h1 {
    font-size: 3rem;
    font-weight: 800;
}

h2 {
    font-weight: 600;
    margin-top: 25px;
    margin-bottom: 3vh !important;
}

.home-section {
    margin-top: -10vh;
    margin-left: 10vh;
    margin-right: 10vh;
    padding-bottom: 10vh;

    .home-text {
        padding-top: 10vh;
    }

    .btn {
        background-color: $accent;
        margin-top: 3vh;
        margin-bottom: 3vh;
    }

    @media (max-width: 600px) {
        margin-top: -5vh;
        margin-left: 2vh;
        margin-right: 2vh;

        .home-text {
            padding-top: 2vh;
        }
    }
}

.explanation-section {
    padding-left: 5vw;
    padding-right: 5vw;
    margin-bottom: 10vh;

    .btn {
        background-color: $accent;
        margin-top: 3vh;
        margin-bottom: 3vh;
    }
}
