/**
 * Html and Body
 */

 

$background-color: #f8f9fa;
// Header and footer
$header-height: 56px;
$footer-height: 56px;
$footer-margin: 30px;

html {
  overflow-x: hidden;
}
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $background-color;
}
main {
  flex: 1;
  .container-fluid {
    // Double the right and left outside padding
    padding-right: $grid-gutter-width;
    padding-left: $grid-gutter-width;
    // Leave margins from header and footer
    overflow-x: auto;
    margin-top: 25px;
    padding-bottom: 20px;
  }
}

/**
 * Header
 */
header {
  margin-bottom: $header-height; // Header height
}

.navbar-dark .navbar-toggler {
  border-color: rgba(255, 255, 255, 0.7);
}
.no-hover-line a {
  text-decoration: none;
}

/**
 *  Images
 */
.img-white {
  filter: brightness(0%) invert(100%);
}
.img-darker {
  filter: brightness(80%);
}



/**
 * Collapsing icon indicating the status (collapsed or not)
 * Just :
 * <a data-toggle="collapse" href="#X" class="collapsed"><i class="fa" aria-hidden="true"></i></a>
 * <div class="row collapse in" id="X">Content</div>
 */
[data-toggle="collapse"] .fa:before {
  content: "\f139";
}

[data-toggle="collapse"].collapsed .fa:before {
  content: "\f13a";
}

/**
 * Site header and navigation
 */

#mainNav {
  background-color: map-get($grays,"100");
  border-bottom: 1px solid rgba(map-get($theme-colors,"dark"), .1);
  box-shadow: 0 3px 3px -2px rgba(0,0,0,0.10);
  margin-bottom: $grid-gutter-width;

  .navbar-toggler {
    height: 42px;
    background-color: transparent;
    border-color: transparent;
  }

  .navbar-brand {
    font-size: 18px;
    @include media-breakpoint-up(md) {
      font-size: 24px;
    }

    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
      color: map-get($theme-colors,"icm-blue");
    }

    &:hover {
      text-decoration: none;
      color:  tint-color($icm-blue, 15%);
    }

    img {
      max-width: 50px;
      height: auto;
      margin: 0 10px 0 0;
    }
  }
}

.appNav {
  a {
    &, &:visited {
      color: $gray-900;
    }
    &:hover {
      color: $gray-700;
      text-decoration: none;
    }
    &.active, &.active:hover {
      background-color: $gray-200;
      font-weight: bolder;
      color: map-get($theme-colors, "icm-blue");
      text-decoration: none;
    }
  }

  // Dropdown hover
  .dropdown:hover>.dropdown-menu {
    display: block;
  }
  .dropdown-menu {
    margin-top: -5px;
  }

  // Limit the width of the user menu
  .width-limited {
    max-width: 150px;
    overflow-x: hidden;
    word-break: keep-all;
    text-overflow: ellipsis;
  }
}


/**
 * Site footer
 */

// Sticky part
//html {
//  position: relative;
//  min-height: 100%;
//}
//body {
//  /* Margin bottom by footer height */
//  margin-bottom: $footer-height+$footer-margin;
//}

.site-footer {
  //position: absolute;
  //bottom: 0;
  //width: 100%;
  /* Set the fixed height of the footer here */
  //height: $footer-height;
  //margin-top: $footer-margin;
}

.footer-nav {
  float: left;
  margin-top: 12px;

  a {
    color: $gray-100;
    &:hover {
      color: white;
    }
    line-height: $line-height-base;
    display: block;
    float: left;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

/**
 * Language switcher
 */

.language-switcher {
  .dropdown-menu {
    min-width: 1rem;
    padding-bottom: 0;
    padding-top: 0;
  }
  .dropdown-item {
    padding-left: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .flag-icon {
    border: 1px solid $gray-700;
    border-radius: 15%;
    line-height: 0.9em;
  }
  // Alias for english flag icon
  .flag-icon-en {
    @extend .flag-icon-gb !optional;
  }
}

/**
 * Forms
 */
.form-group {
  margin-bottom: 0.5rem;
}
.form-group.row label, .form-group.row legend, .form-group .row legend {
  text-align: right;
}
// Forms required labels
form label.required,
form legend.required {
  padding-right: 20px !important;
  position: relative;
  font-weight: 700;
  &:after {
    content:"*";
    color: map-get($theme-colors, "danger");
    display: block;
    position: absolute;
    right: 5px;
    top: 7px;
  }
}